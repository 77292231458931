import { defineStore } from "pinia";
import { _api } from '~/endpoint';
import { ref, onMounted } from 'vue';
import axios from 'axios';
const config = useRuntimeConfig();
const host = config.public.baseURL;
var $privateApi = useNuxtApp().$privateApi;
import cryptoJS from "crypto-js";
import { useUserStore } from "~/store/auth";

export const useCustomerStore = defineStore('UserStore', {
  state: () => ({
    user: null,
    roles: ref([]),
    services: ref([]),
    listFunction: ref([])
  }),
  getters: {
    async getRole(state) {
      const $privateApi = useNuxtApp().$privateApi;
      const dataRole = await $privateApi.get(_api.role.index);
      return dataRole.data;
    },

    getUserNotActive: () => async (centre: any, page: any, limit: any) => {
      const $privateApi = useNuxtApp().$privateApi;
      const dataRole = await $privateApi.get(_api.user.userNotActive, { params: { centre, page: page, limit: limit } });
      return dataRole.data;
    },

    async getFunction(state) {
      const $privateApi = useNuxtApp().$privateApi;
      const dataFunction = await $privateApi.get(_api.function.index);
      return dataFunction.data;
    }
    ,
    async statusUser() {
      const $privateApi = useNuxtApp().$privateApi;
      const data = await $privateApi.get(_api.user.status);
      return data.data;
    }



  },
  actions: {
    async registerUser(form: any) {
      try {
        const $privateApi = useNuxtApp().$privateApi;
        return await $privateApi.post(_api.user.create, form);
      } catch (error) {
        return error;
      }
    },
    async registerUserUnauthentifie(form: any) {
      try {
        const response = await axios.post(host + _api.user.create, form);
        return response.data;
      } catch (error) {
        return error;
      }
    },

    async deleteCustomer(id: any) {
      try {
        // const $privateApi = useNuxtApp().$privateApi;
        const response = await $privateApi.delete(_api.user.delete + id);
        return response.data;
      } catch (error) {
        return error;
      }
    },
    async findByIdUser(id: any) {
      try {
        // const $privateApi = useNuxtApp().$privateApi;
        const response = await $privateApi.delete(_api.user.userByid + id);
        return response.data;
      } catch (error) {
        return error;
      }
    },
    async changecentre(form: any) {
      try {
        console.log('form', form);
        const userStore = useUserStore();
        const response = await $privateApi.post(_api.user.changecentre, form);

        console.log('response', response.data);
        const responseCentre = response.data
        const config = useRuntimeConfig();
        const secureKey = config.public.secureKey;
        const maxAgeInSeconds = 1 * 60 * 60;
        const encryptedToken = cryptoJS.AES.encrypt(responseCentre.token, secureKey).toString();
        if (encryptedToken) {
          userStore.setToken(encryptedToken);
          userStore.setItemWithExpiry(
            "customer",
            JSON.stringify(responseCentre.user),
            maxAgeInSeconds * 1000
          );
          userStore.setCentreConnected(responseCentre.centreConnected);
          return response.data;

        }
      } catch (error) {
        return error;
      }
    },
  },
});
